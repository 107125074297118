import { createI18n } from "vue-i18n";

const messages = {
  en: {
    sideMenu: { accountMenu: { header: { title: "Home" } } },
    account: {
      dashboardView: {
        changePassword: {
          header: {
            title: "Change password",
            subtitle: {
              first: "To change your password a password",
              second: "recovery",
              third: "invite will be sent to your login email",
            },
          },
          fields: {
            email: {
              label: "Email",
              requiredEmail: "Email is a required field",
              invalidEmail: "Must be a valid email",
            },
            submitButton: "Request Reset",
            loading: "Please wait...",
          },
          successMessage: {
            message: {
              first: "An email has been sent to",
              second: ", please follow instructions in that email.",
            },
            confirmButton: "Ok",
          },
        },
      },
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
